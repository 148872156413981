import React from "react"
import { graphql } from "gatsby"
// import { Link } from "gatsby"
import Helmet from "react-helmet"

import Layout from "../components/layout"
import SeO from "../components/seo"

// import { css } from "@emotion/react"
import Form from "../components/form"
import Header from "../components/header"
import Category from "../components/category"
import ForGood from "../components/forgood"
import Quotes from "../components/quotes"

const IndexPage = (props) => {
  const lang = props.data.homepage.lang
  const hp_data = props.data.homepage.data
  const header_title = hp_data.title.text
  const header_subtitle = hp_data.subtitle.text
  const header_intro = hp_data.desc
  const hero_img = hp_data.clients_image

  const categories = props.data.categories.edges.map( edge => edge.node )
  const filtered_lang_categories = categories.filter( category => category.lang === lang ).sort( (a, b) => a.data.product_index - b.data.product_index )

  const products = props.data.products.edges.map( edge => edge.node )

  return(
    <Layout>
      <SeO 
        title="Lyketil Digital Lab - Agence digitale créative à Lausanne - Suisse" 
        description={header_intro}
        lang="fr-fr"
      />

      <Helmet>
        <meta
          name="google-site-verification"
          content="ANRMWCEYLVLwhlc4tQ8hPXHlWTfz5GiH26jrUJC7akM"
        />
      </Helmet>

      <Header 
        title={header_title}
        subtitle={header_subtitle}
        intro={header_intro}
        clients_img={hero_img.fluid}
        lang="fr-fr"
      />

      <div className="container">
        <section>
          {filtered_lang_categories &&
            filtered_lang_categories.map( category => {
              return (
                <Category 
                  key={category.uid}
                  image={category.data.image.fluid.src}
                  uid={category.uid}
                  index={category.data.product_index} 
                  title={category.data.title.text}
                  subtitle={category.data.subtitle.text}
                  desc={category.data.desc}
                  products={products.filter( product => product.data.category.uid === category.uid )}
                />
              )
            })
          }
        </section>

        <section>
          <Form formName="Homepage" />
        </section>

        <section style={{marginTop: "100px", marginBottom: "60px"}}>
          <ForGood />
        </section>
      </div>

      <section>
          <Quotes />
      </section>
    </Layout>
  )
}


export default IndexPage


export const indexQuery = graphql`
  query IndexQuery {
    homepage: prismicHomepage(lang: {eq: "fr-fr"}) {
      lang
      data {
        desc
        subtitle {
          text
        }
        title {
          text
        }
        clients_image {
          fluid(maxWidth: 700) {
            ...GatsbyPrismicImageFluid_noBase64
          }
        }
      }
    }
    categories: allPrismicCategories {
      edges {
        node {
          uid
          lang
          data {
            title {
              text
            }
            subtitle {
              text
            }
            desc
            product_index
            visible_on_homepage
            image {
              fluid(imgixParams: {q: 100}) {
                src
              }
            }
          }
        }
      }
    }
    products: allPrismicProducts(filter: {data: {category: {uid: {regex: "/marketing|multimedia|web/"}}}}) {
      edges {
        node {
          data {
            title {
              text
            }
            category {
              uid
            }
          }
          uid
        }
      }
    }
  }
`
