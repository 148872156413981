import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import { css } from "@emotion/react"
import Button from "./button"

import { FaLeaf } from "react-icons/fa"

const ForGood = (props) => {
    const data = useStaticQuery(graphql`
        query ForGoodQuery {
            background: file(relativePath: { eq: "sustainability-lyketil-suisse-down.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 800) {
                        src
                    }
                }
            }
        }`
    )
    const backgroundImg = data.background.childImageSharp

    return (
        <div css={main} style={backgroundImg && {
            backgroundImage: `url(${backgroundImg.fluid.src})`,
          }}>
            <h3>Notre engagement <b>#Digital<span>For</span>Good</b></h3>
            <h2>Agence digitale pour ONGs et projets soutenant les Sustainable Development Goals (SDGs) de l'ONU.</h2>
            <p>Par nos choix d'expériences et clients, nous portons une attention particulière à mettre le plus souvent possible notre énergie, passion et nos compétences digitales au service de projets œuvrant à la justice sociale ou environnementale.</p>
            <Button link={`/#contact`} text={"Travaillons ensemble"} icon={<FaLeaf />} />
        </div>
    )
}

export default ForGood

const main = css`
    padding: 40px 40px 10px;
    border-radius: 30px;
    background-color: white;
    background-size: cover;

    h3 {
        text-transform: uppercase;
        font-weight: 500;
        font-size: 13px;
        letter-spacing: 1px;

        b {
            color: forestgreen;
        }

        span {
            font-weight: 400;
        }
    }

    h2 {
        font-size: 36px;

        @media screen and (max-width: 800px) {
            font-size: 26px;
        }
    }

    p {
        font-size: 20px;
        opacity: .66;

        @media screen and (max-width: 800px) {
            font-size: 16px;
        }
    }
`